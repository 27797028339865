import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { addDays, format } from "date-fns";
import { getBrandStatistics } from '../services/brandService';
import { Star, StarHalf, BarChart2, Globe, LineChart, Share2, Users, Calendar } from 'lucide-react';
import { COUNTRIES } from '../constants/countries';

const getCountryFlag = (countryCode) => {
  return countryCode === 'all_countries' ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.6 9H20.4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.6 15H20.4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3C14.0833 5.04 15.2222 7.38 15.2222 12C15.2222 16.62 14.0833 18.96 12 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3C9.91667 5.04 8.77778 7.38 8.77778 12C8.77778 16.62 9.91667 18.96 12 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ) : (
    <img
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`}
      alt={countryCode}
      className="w-6 h-4 object-cover rounded"
    />
  );
};

const getCountryLabel = (countryCode) => {
  if (countryCode === 'all_countries') return 'All Countries';
  const country = COUNTRIES.find(c => c.value === countryCode);
  return country ? country.label : countryCode;
};

const BrandStatistics = ({ brandId }) => {
  // Основные состояния
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояния для дат
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());

  // Состояния для сравнения брендов
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('all_countries');
  const [selectedGender, setSelectedGender] = useState('all_genders');
  const [selectedAge, setSelectedAge] = useState('all_ages');

  const getMonthIndex = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months.indexOf(date.split(' ')[0]);
  };

  // Добавить в начало компонента
  const getPlatformColor = (platform) => {
    const colors = {
      Discord: "#5865F2",
      Facebook: "#1877F2",
      Instagram: "#E4405F",
      KakaoTalk: "#FAE100",
      Line: "#00B900",
      LinkedIn: "#0A66C2",
      Medium: "#000000",
      Odnoklassniki: "#EE8208",
      OnlyFans: "#00AFF0",
      Patreon: "#FF424D",
      Pinterest: "#BD081C",
      QQ: "#EB1923",
      Reddit: "#FF4500",
      Snapchat: "#FFFC00",
      Telegram: "#26A5E4",
      Threads: "#000000",
      TikTok: "#000000",
      Tumblr: "#36465D",
      Twitch: "#9146FF",
      Viber: "#7360F2",
      VK: "#0077FF",
      WeChat: "#07C160",
      Weibo: "#E6162D",
      WhatsApp: "#25D366",
      X: "#000000",
      YouTube: "#FF0000"
    };

    return colors[platform] || "#808080"; // Серый цвет по умолчанию
  };

  // Получение данных
  useEffect(() => {
    const fetchStats = async () => {
      if (!brandId) return;

      try {
        // Set default date range to last 7 days if not set
        const defaultEndDate = new Date();
        const defaultStartDate = addDays(defaultEndDate, -7);

        const data = await getBrandStatistics(brandId, {
          startDate: (startDate || defaultStartDate).toISOString(),
          endDate: (endDate || defaultEndDate).toISOString()
        });

        if (!data) throw new Error('No data received');

        // Создаем массив для хранения ежедневной статистики
        const dailyStats = [];
        const battleHistory = data.battleHistory || [];

        // Создаем Map для группировки данных по дням
        const statsMap = new Map();

        // Обрабатываем историю битв
        battleHistory.forEach(battle => {
          const date = new Date(battle.timestamp);
          const dateKey = format(date, 'yyyy-MM-dd');

          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }

          const dayStats = statsMap.get(dateKey);
          dayStats.totalBattles++;
          if (battle.result === 'win') {
            dayStats.wins++;
          } else {
            dayStats.losses++;
          }
        });

        // Обрабатываем клики по веб-сайту
        (data.websiteClicks?.history || []).forEach(click => {
          const date = new Date(click.timestamp);
          const dateKey = format(date, 'yyyy-MM-dd');

          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }

          statsMap.get(dateKey).websiteClicks++;
        });

        // Обрабатываем клики по социальным сетям
        (data.socialMediaClicks || []).forEach(platform => {
          platform.history?.forEach(click => {
            const date = new Date(click.timestamp);
            const dateKey = format(date, 'yyyy-MM-dd');

            if (!statsMap.has(dateKey)) {
              statsMap.set(dateKey, {
                date: dateKey,
                totalBattles: 0,
                wins: 0,
                losses: 0,
                websiteClicks: 0,
                socialClicks: 0
              });
            }

            statsMap.get(dateKey).socialClicks++;
          });
        });

        // Заполняем пропущенные даты между startDate и endDate
        let currentDate = startDate || defaultStartDate;
        const endDateToUse = endDate || defaultEndDate;

        while (currentDate <= endDateToUse) {
          const dateKey = format(currentDate, 'yyyy-MM-dd');
          if (!statsMap.has(dateKey)) {
            statsMap.set(dateKey, {
              date: dateKey,
              totalBattles: 0,
              wins: 0,
              losses: 0,
              websiteClicks: 0,
              socialClicks: 0
            });
          }
          currentDate = addDays(currentDate, 1);
        }

        // Преобразуем Map в отсортированный массив
        const sortedDailyStats = Array.from(statsMap.entries())
          .map(([date, stats]) => ({
            name: format(new Date(date), 'MMM dd'),
            battles: stats.totalBattles,
            wins: stats.wins,
            losses: stats.losses,
            websiteClicks: stats.websiteClicks,
            socialClicks: stats.socialClicks
          }))
          .sort((a, b) => new Date(a.name) - new Date(b.name));

        // Обработка демографической статистики
        const processedDemographics = {
          genderStats: (data.demographics?.genderStats || []).map(stat => ({
            gender: stat.gender,
            totalBattles: Number(stat.totalBattles) || 0,
            wins: Number(stat.wins) || 0,
            losses: Number(stat.losses) || 0,
            percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
          })).filter(stat => stat.totalBattles > 0),
          ageStats: (data.demographics?.ageStats || []).map(stat => ({
            ageRange: stat.ageRange,
            totalBattles: Number(stat.totalBattles) || 0,
            wins: Number(stat.wins) || 0,
            losses: Number(stat.losses) || 0,
            percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
          })).filter(stat => stat.totalBattles > 0)
        };

        // Обработка статистики по странам
        const processedCountryStats = (data.countryStats || []).map(country => ({
          country: country.country,
          totalBattles: Number(country.totalBattles) || 0,
          wins: Number(country.wins) || 0,
          losses: Number(country.losses) || 0,
          winRate: country.totalBattles > 0 ? ((country.wins / country.totalBattles) * 100).toFixed(1) : '0',
          demographics: {
            genderStats: (country.demographics?.genderStats || []).map(stat => ({
              gender: stat.gender,
              totalBattles: Number(stat.totalBattles) || 0,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0
            })).filter(stat => stat.totalBattles > 0),
            ageStats: (country.demographics?.ageStats || []).map(stat => ({
              ageRange: stat.ageRange,
              totalBattles: Number(stat.totalBattles) || 0,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0
            })).filter(stat => stat.totalBattles > 0)
          }
        })).filter(country => country.totalBattles > 0);

        setStats({
          totalBattles: data.totalBattles || 0,
          totalWins: data.totalWins || 0,
          rating: data.rating || 0,
          dailyStats: sortedDailyStats,
          demographics: processedDemographics,
          countryStats: processedCountryStats,
          socialMediaClicks: data.socialMediaClicks || [],
          battleHistory: data.battleHistory || [],
          socialMedia: {
            websiteClicks: data.websiteClicks?.total || 0,
            platforms: (data.socialMediaClicks || []).map(platform => ({
              name: platform.platform,
              clicks: Number(platform.total) || 0
            }))
          },
          comparisons: (data.comparisons || []).map(comparison => ({
            competitorBrandId: comparison.competitorBrandId,
            competitorBrand: comparison.competitorBrand,
            competitorLogo: comparison.competitorLogo,
            wins: Number(comparison.wins) || 0,
            losses: Number(comparison.losses) || 0,
            totalBattles: Number(comparison.wins + comparison.losses) || 0,
            winRate: comparison.wins > 0 ? ((comparison.wins / (comparison.wins + comparison.losses)) * 100).toFixed(1) : '0',
            countryStats: comparison.countryStats?.map(stat => ({
              country: stat.country,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.wins + stat.losses) || 0,
              winRate: stat.wins > 0 ? ((stat.wins / (stat.wins + stat.losses)) * 100).toFixed(1) : '0',
              demographics: {
                genderStats: (stat.demographics?.genderStats || []).map(g => ({
                  gender: g.gender,
                  wins: Number(g.wins) || 0,
                  losses: Number(g.losses) || 0,
                  totalBattles: Number(g.totalBattles) || 0
                })).filter(g => g.totalBattles > 0),
                ageStats: (stat.demographics?.ageStats || []).map(a => ({
                  ageRange: a.ageRange,
                  wins: Number(a.wins) || 0,
                  losses: Number(a.losses) || 0,
                  totalBattles: Number(a.totalBattles) || 0
                })).filter(a => a.totalBattles > 0)
              }
            })).filter(stat => stat.totalBattles > 0),
            dailyStats: (comparison.dailyStats || []).map(stat => ({
              date: stat.date,
              wins: Number(stat.wins) || 0,
              losses: Number(stat.losses) || 0,
              totalBattles: Number(stat.totalBattles) || 0,
              winRate: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
            })).sort((a, b) => new Date(a.date) - new Date(b.date)),
            demographics: {
              genderStats: (comparison.demographics?.genderStats || []).map(stat => ({
                gender: stat.gender,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0,
                percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
              })).filter(stat => stat.totalBattles > 0),
              ageStats: (comparison.demographics?.ageStats || []).map(stat => ({
                ageRange: stat.ageRange,
                wins: Number(stat.wins) || 0,
                losses: Number(stat.losses) || 0,
                totalBattles: Number(stat.totalBattles) || 0,
                percentage: stat.totalBattles > 0 ? ((stat.wins / stat.totalBattles) * 100).toFixed(1) : '0'
              })).filter(stat => stat.totalBattles > 0)
            }
          }))
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [brandId, startDate, endDate]);

  // Функция для получения статистики по стране
  const getCountryStats = () => {
    console.log('=== getCountryStats START ===');
    console.log('Selected country:', selectedCountry);
    console.log('Stats object:', stats);

    if (!selectedCountry || selectedCountry === 'all_countries' || !stats?.countryStats) {
      console.log('Early return conditions met:');
      console.log('- selectedCountry:', selectedCountry);
      console.log('- stats?.countryStats exists:', !!stats?.countryStats);
      return null;
    }

    const countryData = stats.countryStats.find(stat => stat.country === selectedCountry);
    console.log('Found country data:', countryData);

    if (!countryData) {
      console.log('No country data found');
      return null;
    }

    // Проверяем наличие battleHistory
    if (!stats?.battleHistory) {
      console.log('No battle history found');
      return {
        totalBattles: countryData.totalBattles || 0,
        wins: countryData.wins || 0,
        demographics: {
          genderStats: countryData.demographics?.genderStats || [],
          ageStats: countryData.demographics?.ageStats || []
        },
        dailyStats: []
      };
    }

    // Фильтруем битвы для выбранной страны
    const countryBattles = stats.battleHistory.filter(battle =>
      (battle.userCountry === selectedCountry || battle.ipCountry === selectedCountry) &&
      new Date(battle.timestamp) >= startDate &&
      new Date(battle.timestamp) <= endDate
    );
    console.log('Filtered country battles:', countryBattles);

    // Создаем массив для хранения статистики по дням
    const statsMap = new Map();

    // Заполняем все даты в диапазоне
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      const dateKey = format(currentDate, 'yyyy-MM-dd');
      statsMap.set(dateKey, {
        date: dateKey,
        totalBattles: 0,
        wins: 0,
        losses: 0
      });
      currentDate = addDays(currentDate, 1);
    }

    // Заполняем статистику из отфильтрованных битв
    countryBattles.forEach(battle => {
      const dateKey = format(new Date(battle.timestamp), 'yyyy-MM-dd');
      const dayStats = statsMap.get(dateKey);

      if (dayStats) {
        dayStats.totalBattles++;
        if (battle.result === 'win') {
          dayStats.wins++;
        } else {
          dayStats.losses++;
        }
        console.log('Updated stats for date:', dateKey, dayStats);
      }
    });

    // Конвертируем Map в массив и сортируем по дате
    const sortedDailyStats = Array.from(statsMap.values())
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    console.log('Daily stats object:', Object.fromEntries(statsMap));
    console.log('Sorted daily stats:', sortedDailyStats);

    const result = {
      totalBattles: countryData.totalBattles || 0,
      wins: countryData.wins || 0,
      demographics: {
        genderStats: countryData.demographics?.genderStats || [],
        ageStats: countryData.demographics?.ageStats || []
      },
      dailyStats: sortedDailyStats
    };

    console.log('=== getCountryStats RESULT ===', result);
    return result;
  };
  // Функция для получения статистики сравнения
  const getComparisonStats = () => {
    if (!selectedBrand || !stats?.comparisons) {
      return null;
    }

    const comparison = stats.comparisons.find(c => c.competitorBrandId === selectedBrand);
    if (!comparison) {
      return null;
    }

    // Преобразуем даты начала и конца в UTC для корректного сравнения
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    // Получаем битвы только с выбранным брендом из истории и в диапазоне дат
    const relevantBattles = (stats.battleHistory || []).filter(battle => {
      const battleTime = new Date(battle.timestamp).getTime();
      return battle.competitorId === selectedBrand &&
        battleTime >= startDateTime &&
        battleTime <= endDateTime;
    });

    // Создаем Map для группировки по датам
    const battlesMap = new Map();

    // Обрабатываем каждую битву
    relevantBattles.forEach(battle => {
      const dateKey = format(new Date(battle.timestamp), 'yyyy-MM-dd');
      if (!battlesMap.has(dateKey)) {
        battlesMap.set(dateKey, {
          wins: 0,
          losses: 0
        });
      }

      const stats = battlesMap.get(dateKey);
      if (battle.result === 'win') {
        stats.wins++;
      } else {
        stats.losses++;
      }
    });

    // Создаем массив дат между startDate и endDate
    const dates = [];
    let currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      const dateKey = format(currentDate, 'yyyy-MM-dd');
      const dayStats = battlesMap.get(dateKey) || { wins: 0, losses: 0 };

      dates.push({
        date: dateKey,
        name: format(currentDate, 'MMM dd'),
        wins: dayStats.wins,
        losses: dayStats.losses
      });

      currentDate = addDays(currentDate, 1);
    }

    const hasData = dates.some(date => date.wins > 0 || date.losses > 0);
    return {
      ...comparison,
      dailyStats: dates,
      hasData
    };
  };

  // Вспомогательные функции
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<Star key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<StarHalf key={i} className="text-[#e0ff89] inline w-4 h-4" />);
      } else {
        stars.push(<Star key={i} className="text-gray-400 inline w-4 h-4" />);
      }
    }
    return stars;
  };

  if (loading) return <div className="text-center py-4 text-white">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!stats) return <div className="text-center py-4 text-white">No statistics available.</div>;

  return (
    <Card className="bg-[#1a1a1a] text-white rounded-[20px] overflow-hidden">
      <CardHeader className="bg-[#282828] px-4 py-3">
        <div className="flex justify-between items-center">
          <CardTitle className="text-lg font-bold flex items-center gap-2">
            <BarChart2 className="w-5 h-5 text-[#e0ff89]" />
            Brand Statistics
          </CardTitle>
          <div className="flex items-center gap-2">
            {renderStars(stats.rating)}
            <span className="text-xl font-bold">{stats.rating.toFixed(2)}</span>
          </div>
        </div>
      </CardHeader>

      <CardContent className="p-4 space-y-6">
        {/* Основные показатели и график по дням */}
        <div>
          {/* Дата пикер */}
          <div className="flex gap-4 mb-4">
            <div className="flex-1 relative">
              <label className="text-sm text-gray-400 mb-1 block">Start Date</label>
              <input
                type="date"
                value={format(startDate, 'yyyy-MM-dd')}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setStartDate(date);
                }}
                className="w-full bg-[#282828] text-white border border-gray-600 rounded-md p-2"
              />
            </div>
            <div className="flex-1 relative">
              <label className="text-sm text-gray-400 mb-1 block">End Date</label>
              <input
                type="date"
                value={format(endDate, 'yyyy-MM-dd')}
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  setEndDate(date);
                }}
                className="w-full bg-[#282828] text-white border border-gray-600 rounded-md p-2"
              />
            </div>
          </div>

          {/* Основные показатели в строку */}
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Total Battles</p>
              <p className="text-2xl font-bold">{stats.totalBattles}</p>
            </div>
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Total Wins</p>
              <p className="text-2xl font-bold">{stats.totalWins}</p>
            </div>
            <div className="bg-[#282828] p-4 rounded-xl">
              <p className="text-[#e0ff89] text-sm mb-2">Win Rate</p>
              <p className="text-2xl font-bold">
                {stats.totalBattles > 0
                  ? ((stats.totalWins / stats.totalBattles) * 100).toFixed(1)
                  : '0'}%
              </p>
            </div>
          </div>

{/* График по дням */}
<div className="bg-[#282828] p-4 rounded-xl">
  <h4 className="text-sm mb-4">Daily Statistics</h4>
  <div className="h-64">
    {(() => {
      if (!stats || !stats.dailyStats) {
        return (
          <div className="flex items-center justify-center h-full text-gray-400">
            <p>No data for selected date range</p>
          </div>
        );
      }

      // Преобразуем массив в данные для графика
      const chartData = stats.dailyStats.map(day => ({
        name: day.name,
        wins: day.wins || 0,
        losses: day.battles ? (day.battles - (day.wins || 0)) : 0
      }));

      // Отфильтруем по диапазону дат
      const filteredData = chartData.filter(item => {
        try {
          const [month, day] = item.name.split(' ');
          const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
          const currentYear = startDate.getFullYear();
          const itemDate = new Date(currentYear, monthIndex, parseInt(day));
          return itemDate >= startDate && itemDate <= endDate;
        } catch (error) {
          console.error("Error processing date:", item.name, error);
          return false;
        }
      });

      // Фильтруем дни где есть хотя бы одна битва
      const dataWithBattles = filteredData.filter(day => (day.wins + day.losses) > 0);

      if (dataWithBattles.length === 0) {
        return (
          <div className="flex items-center justify-center h-full text-gray-400">
            <p>No data for selected date range</p>
          </div>
        );
      }

      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={dataWithBattles}
            margin={{
              top: 20,
              right: 10,
              left: 10,
              bottom: 20,
            }}
          >
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#9ca3af' }}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null;
                const data = payload[0].payload;
                return (
                  <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                    <p className="text-sm font-bold">{data.name}</p>
                    <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                    <p className="text-sm text-[#ef4444]">Losses: {data.losses}</p>
                    <p className="text-sm text-gray-400">Total: {data.wins + data.losses}</p>
                  </div>
                );
              }}
            />
            <Bar
              dataKey="losses"
              stackId="stack"
              fill="#ef4444"
              radius={[4, 4, 0, 0]}
            />
            <Bar
              dataKey="wins"
              stackId="stack"
              fill="#e0ff89"
              radius={[0, 0, 4, 4]}
            />
          </BarChart>
        </ResponsiveContainer>
                );
              })()}
            </div>
          </div>
        </div>

        {/* Сравнение брендов */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <p className="text-[#e0ff89] text-sm flex items-center gap-2">
              <LineChart className="w-4 h-4" />
              Brand Comparison
            </p>
          </div>

          <Select
            value={selectedBrand}
            onValueChange={setSelectedBrand}
          >
            <SelectTrigger className="bg-black text-white border-gray-600">
              <SelectValue placeholder="Select Brand to Compare" />
            </SelectTrigger>
            <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
              {stats?.comparisons && stats.comparisons.length > 0 ? (
                stats.comparisons.map((comp) => (
                  <SelectItem
                    key={comp.competitorBrandId}
                    value={comp.competitorBrandId}
                  >
                    <div className="flex items-center gap-2">
                      {comp.competitorLogo && (
                        <img
                          src={comp.competitorLogo}
                          alt={comp.competitorBrand}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                      )}
                      <span>{comp.competitorBrand}</span>
                    </div>
                  </SelectItem>
                ))
              ) : (
                <SelectItem value="no_data" disabled>
                  <span className="text-gray-500">No comparisons available</span>
                </SelectItem>
              )}
            </SelectContent>
          </Select>

          {selectedBrand && (
            <>
              {/* Статистика сравнения */}
              <div className="bg-[#282828] p-4 rounded-xl">
                <div className="mb-4">
                  <h4 className="text-lg font-bold">Comparison Results</h4>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div>
                      <p className="text-sm text-gray-400">Wins</p>
                      <p className="text-2xl font-bold text-[#e0ff89]">
                        {getComparisonStats().wins}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400">Losses</p>
                      <p className="text-2xl font-bold text-red-400">
                        {getComparisonStats().losses}
                      </p>
                    </div>
                  </div>
                </div>

{/* График сравнения */}
<div className="h-64">
  {(() => {
    const comparisonStats = stats.comparisons?.find(c =>
      c.competitorBrandId === selectedBrand
    );

    if (!comparisonStats || !selectedBrand) {
      console.log("No data to render chart:", {
        comparisonStats: !!comparisonStats,
        selectedBrand: !!selectedBrand
      });
      return (
        <div className="flex items-center justify-center h-full text-gray-400">
          <p>Please select a brand to compare</p>
        </div>
      );
    }

    const chartData = comparisonStats.dailyStats.map(day => {
      const dataPoint = {
        name: format(new Date(day.date), 'MMM dd'),
        total: day.wins + day.losses,
        // Сохраняем для тултипа
        wins: day.wins,
        losses: day.losses
      };
      console.log("Generated chart data point:", dataPoint);
      return dataPoint;
    });

    // Проверяем, есть ли данные для отображения
    const hasData = chartData.some(data => data.wins > 0 || data.losses > 0);
    if (!hasData) {
      return (
        <div className="flex items-center justify-center h-full text-gray-400">
          <p>No comparison data available for selected date range</p>
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 10,
            left: 10,
            bottom: 20,
          }}
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#9ca3af' }}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload?.length) return null;
              return (
                <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                  <p className="text-sm font-bold">{payload[0].payload.name}</p>
                  <p className="text-sm text-[#e0ff89]">Wins: {payload[0].payload.wins}</p>
                  <p className="text-sm text-[#ef4444]">Losses: {payload[0].payload.losses}</p>
                </div>
              );
            }}
          />
          <Bar
            dataKey="losses"
            stackId="stack"
            fill="#ef4444"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="wins"
            stackId="stack"
            fill="#e0ff89"
            radius={[0, 0, 4, 4]}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  })()}
                </div>
              </div>
            </>
          )}
        </div>

        {/* Социальная активность */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Share2 className="w-4 h-4" />
            Social Media Activity
          </p>

          <div className="bg-[#282828] p-4 rounded-xl">
            {/* Статистика кликов */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-4">
              <div>
                <p className="text-sm text-gray-400">Website Clicks</p>
                <p className="text-xl font-bold">
                  {(() => {
                    return stats.socialMedia.websiteClicks || 0;
                  })()}
                </p>
              </div>
              {stats.socialMedia?.platforms?.map((platform) => {
                return (
                  <div key={platform.name}>
                    <p className="text-sm text-gray-400">{platform.name}</p>
                    <p className="text-xl font-bold">{platform.clicks || 0}</p>
                  </div>
                );
              })}
            </div>

            {/* График активности */}
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={(() => {
                    // Фильтруем данные по выбранному диапазону дат
                    const filteredData = stats.dailyStats.filter(day => {
                      const [month, dayNum] = day.name.split(' ');
                      const monthIndex = getMonthIndex(day.name);
                      const dayDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));
                      return dayDate >= startDate && dayDate <= endDate;
                    });

                    // Преобразуем данные для графика
                    const transformedData = filteredData.map(day => {
                      const [month, dayNum] = day.name.split(' ');
                      const monthIndex = getMonthIndex(day.name);
                      const formattedDate = new Date(startDate.getFullYear(), monthIndex, parseInt(dayNum));

                      // Базовый объект дня
                      const dayData = {
                        name: day.name,
                        websiteClicks: day.websiteClicks || 0
                      };

                      // Добавляем клики по соц сетям
                      if (stats.socialMediaClicks) {
                        stats.socialMediaClicks.forEach(platform => {
                          const platformKey = `${platform.platform.toLowerCase()}Clicks`;

                          // Фильтруем клики для текущего дня
                          const platformClicks = platform.history.filter(click => {
                            const clickDate = new Date(click.timestamp);
                            const isSameDay = format(clickDate, 'yyyy-MM-dd') === format(formattedDate, 'yyyy-MM-dd');
                            return isSameDay;
                          }).length;

                          if (platformClicks > 0) { // Добавляем только если есть клики
                            dayData[platformKey] = platformClicks;
                          }
                        });
                      }
                      return dayData;
                    });

                    // Фильтруем дни где есть хотя бы один клик
                    return transformedData.filter(day => {
                      const totalClicks = Object.values(day).reduce((sum, value) => {
                        return typeof value === 'number' ? sum + value : sum;
                      }, 0) - (day.name ? 1 : 0); // Вычитаем 1 если есть name, чтобы не учитывать его в сумме
                      return totalClicks > 0;
                    });
                  })()}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (!active || !payload?.length) return null;

                      const nonZeroClicks = payload.filter(p => p.value > 0);

                      return (
                        <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                          <p className="text-sm font-bold mb-1">{label}</p>
                          {nonZeroClicks.map((entry, index) => (
                            <p key={index} className="text-sm" style={{ color: entry.color }}>
                              {`${entry.name.replace('Clicks', '')}: ${entry.value}`}
                            </p>
                          ))}
                        </div>
                      );
                    }}
                  />
                  <Bar
                    dataKey="websiteClicks"
                    name="Website"
                    fill="#e0ff89"
                    stackId="a"
                  />
                  {stats.socialMediaClicks?.map(platform => {
                    const platformKey = `${platform.platform.toLowerCase()}Clicks`;
                    return (
                      <Bar
                        key={platformKey}
                        dataKey={platformKey}
                        name={platform.platform}
                        fill={getPlatformColor(platform.platform)}
                        stackId="a"
                      />
                    );
                  })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Демографическая статистика */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Users className="w-4 h-4" />
            Demographics Overview
          </p>

          {/* Статистика по полу */}
          <div className="bg-[#282828] p-4 rounded-xl">
            <h4 className="text-sm mb-4">Gender Statistics</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {stats.demographics.genderStats.map((stat) => (
                <div key={stat.gender} className="bg-[#1a1a1a] p-3 rounded-lg">
                  <p className="text-sm capitalize">{stat.gender}</p>
                  <p className="text-2xl font-bold">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={stats.demographics.genderStats.map(stat => ({
                    name: stat.gender,
                    wins: stat.wins,
                    losses: stat.losses,
                    total: stat.totalBattles
                  }))}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (!active || !payload?.length) return null;
                      return (
                        <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                          <p className="text-sm font-bold capitalize">{payload[0].payload.name}</p>
                          <p className="text-sm text-[#e0ff89]">Wins: {payload[0].payload.wins}</p>
                          <p className="text-sm text-red-400">Losses: {payload[0].payload.losses}</p>
                          <p className="text-sm text-gray-400">Total: {payload[0].payload.total}</p>
                        </div>
                      );
                    }}
                  />
                  <Bar
                    dataKey="losses"
                    stackId="stack"
                    fill="#ef4444"
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar
                    dataKey="wins"
                    stackId="stack"
                    fill="#e0ff89"
                    radius={[0, 0, 4, 4]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Статистика по возрасту */}
          <div className="bg-[#282828] p-4 rounded-xl">
            <h4 className="text-sm mb-4">Age Statistics</h4>
            <div className="grid grid-cols-3 gap-4 mb-4">
              {stats.demographics.ageStats.map((stat) => (
                <div key={stat.ageRange} className="bg-[#1a1a1a] p-3 rounded-lg">
                  <p className="text-sm">{stat.ageRange}</p>
                  <p className="text-2xl font-bold">
                    {((stat.wins / stat.totalBattles) * 100).toFixed(1)}%
                  </p>
                  <p className="text-sm text-gray-400">
                    {stat.totalBattles} battles
                  </p>
                </div>
              ))}
            </div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={stats.demographics.ageStats.map(stat => ({
                    name: stat.ageRange,
                    wins: stat.wins,
                    losses: stat.losses,
                    total: stat.totalBattles
                  }))}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 10,
                    bottom: 20,
                  }}
                >
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: '#9ca3af' }}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (!active || !payload?.length) return null;
                      return (
                        <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                          <p className="text-sm font-bold">{payload[0].payload.name}</p>
                          <p className="text-sm text-[#e0ff89]">Wins: {payload[0].payload.wins}</p>
                          <p className="text-sm text-red-400">Losses: {payload[0].payload.losses}</p>
                          <p className="text-sm text-gray-400">Total: {payload[0].payload.total}</p>
                        </div>
                      );
                    }}
                  />
                  <Bar
                    dataKey="losses"
                    stackId="stack"
                    fill="#ef4444"
                    radius={[4, 4, 0, 0]}
                  />
                  <Bar
                    dataKey="wins"
                    stackId="stack"
                    fill="#e0ff89"
                    radius={[0, 0, 4, 4]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Статистика по странам */}
        <div className="space-y-4">
          <p className="text-[#e0ff89] text-sm flex items-center gap-2">
            <Globe className="w-4 h-4" />
            Country Statistics
          </p>

          <Select value={selectedCountry} onValueChange={setSelectedCountry}>
            <SelectTrigger className="bg-black text-white border-gray-600">
              <SelectValue placeholder="Select Country">
                {selectedCountry && (
                  <div className="flex items-center gap-2">
                    {getCountryFlag(selectedCountry)}
                    <span>{getCountryLabel(selectedCountry)}</span>
                  </div>
                )}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="bg-[#1a1a1a] border-gray-600 z-[999]">
              <SelectItem value="all_countries">
                <div className="flex items-center gap-2">
                  {getCountryFlag('all_countries')}
                  <span>All Countries</span>
                </div>
              </SelectItem>
              {stats?.countryStats?.length > 0 ? (
                stats.countryStats.map((stat) => (
                  <SelectItem key={stat.country} value={stat.country || 'undefined'}>
                    <div className="flex items-center gap-2">
                      {getCountryFlag(stat.country)}
                      <span>{getCountryLabel(stat.country)}</span>
                    </div>
                  </SelectItem>
                ))
              ) : (
                <SelectItem value="no_data">No countries available</SelectItem>
              )}
            </SelectContent>
          </Select>

          {selectedCountry !== 'all' && (
            <div className="space-y-4">
              {/* Основные показатели страны */}
              {getCountryStats() ? (
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Total Battles</p>
                    <p className="text-2xl font-bold">
                      {getCountryStats()?.totalBattles || 0}
                    </p>
                  </div>
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Total Wins</p>
                    <p className="text-2xl font-bold text-[#e0ff89]">
                      {getCountryStats()?.wins || 0}
                    </p>
                  </div>
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <p className="text-sm text-gray-400">Win Rate</p>
                    <p className="text-2xl font-bold">
                      {getCountryStats()?.totalBattles ?
                        ((getCountryStats().wins / getCountryStats().totalBattles) * 100).toFixed(1)
                        : '0'}%
                    </p>
                  </div>
                </div>
              ) : (
                <div className="bg-[#282828] p-4 rounded-xl text-center">
                  <p className="text-gray-400">No data available for selected country</p>
                </div>
              )}

              {/* Демографическая статистика страны */}
              {getCountryStats() && (
                <div className="grid grid-cols-2 gap-4">
                  {/* Статистика по полу */}
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <h4 className="text-sm mb-3">Gender Distribution</h4>
                    <div className="space-y-2">
                      {getCountryStats()?.demographics?.genderStats?.map((stat) => (
                        <div key={stat.gender} className="flex justify-between items-center">
                          <span className="text-sm capitalize">{stat.gender}</span>
                          <span className="text-sm text-[#e0ff89]">
                            {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                          </span>
                        </div>
                      )) || <div className="text-gray-400">No gender data available</div>}
                    </div>
                  </div>

                  {/* Статистика по возрасту */}
                  <div className="bg-[#282828] p-4 rounded-xl">
                    <h4 className="text-sm mb-3">Age Distribution</h4>
                    <div className="space-y-2">
                      {getCountryStats()?.demographics?.ageStats?.map((stat) => (
                        <div key={stat.ageRange} className="flex justify-between items-center">
                          <span className="text-sm">{stat.ageRange}</span>
                          <span className="text-sm text-[#e0ff89]">
                            {((stat.wins / (stat.totalBattles || 1)) * 100).toFixed(1)}%
                          </span>
                        </div>
                      )) || <div className="text-gray-400">No age data available</div>}
                    </div>
                  </div>
                </div>
              )}

              {/* График по дням */}
              {getCountryStats() && (
                <div className="bg-[#282828] p-4 rounded-xl">
                  <h4 className="text-sm mb-4">Daily Statistics</h4>
                  <div className="h-64">
                    {(() => {
                      console.log('=== Rendering country chart ===');

                      if (!selectedCountry || selectedCountry === 'all_countries') {
                        return (
                          <div className="flex items-center justify-center h-full text-gray-400">
                            <p>Please select a country to view statistics</p>
                          </div>
                        );
                      }

                      const countryStats = getCountryStats();
                      if (!countryStats || !countryStats.dailyStats) {
                        return (
                          <div className="flex items-center justify-center h-full text-gray-400">
                            <p>No data available for this country</p>
                          </div>
                        );
                      }

                      // Фильтруем дни только с данными
                      const chartData = countryStats.dailyStats
                        .filter(day => day.totalBattles > 0)
                        .map(day => ({
                          name: format(new Date(day.date), 'MMM dd'),
                          wins: day.wins,
                          losses: day.losses,
                          total: day.totalBattles
                        }));

                      if (chartData.length === 0) {
                        return (
                          <div className="flex items-center justify-center h-full text-gray-400">
                            <p>No battles data for selected period</p>
                          </div>
                        );
                      }

                      return (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={chartData}
                            margin={{
                              top: 20,
                              right: 10,
                              left: 10,
                              bottom: 20,
                            }}
                          >
                            <XAxis
                              dataKey="name"
                              axisLine={false}
                              tickLine={false}
                              tick={{ fill: '#9ca3af' }}
                            />
                            <Tooltip
                              content={({ active, payload }) => {
                                if (!active || !payload?.length) return null;
                                const data = payload[0].payload;
                                return (
                                  <div className="bg-[#1a1a1a] p-2 rounded-lg border border-gray-700">
                                    <p className="text-sm font-bold">{data.name}</p>
                                    <p className="text-sm text-[#e0ff89]">Wins: {data.wins}</p>
                                    <p className="text-sm text-red-400">Losses: {data.losses}</p>
                                    <p className="text-sm text-gray-400">Total: {data.total}</p>
                                  </div>
                                );
                              }}
                            />
                            <Bar
                              dataKey="losses"
                              stackId="stack"
                              fill="#ef4444"
                              radius={[4, 4, 0, 0]}
                            />
                            <Bar
                              dataKey="wins"
                              stackId="stack"
                              fill="#e0ff89"
                              radius={[0, 0, 4, 4]}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      );
                    })()}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandStatistics;